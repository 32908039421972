@import "colors";
@font-face {
    font-family: inter-regular;
    src: url("../fonts/Inter-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-family: inter-SemiBold;
    src: url("../fonts/Inter-SemiBold.ttf");
    font-display: swap;
}
@font-face {
    font-family: inter-Medium;
    src: url("../fonts/Inter-Medium.ttf");
    font-display: swap;
}
@font-face {
    font-family: inter-Bold;
    src: url("../fonts/Inter-Bold.ttf");
    font-display: swap;
}
@font-face {
    font-family: Inter-ExtraBold;
    src: url("../fonts/Inter-ExtraBold.ttf");
    font-display: swap;
}
.ExtraBold {
    font-family: Inter-ExtraBold;
}
.semibold {
    font-family: inter-SemiBold;
}
.medium {
    font-family: inter-medium;
}
.bold {
    font-family: inter-Bold;
}
p, span, label {
    font-family: inter-regular;
}  
.paragraph {
    color: $gray;
}
body {
    background-image: url("../imgs/background.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.gray-bg {
    background-color: $lite-white;
} 
.svg-input {
    background-color: $lite-white;
    color: $gray;
    border: 1px solid $gray;
    &:focus-within {
        border: 1px solid rgb(255, 0, 0);
        box-shadow: 0 15px 35px 0 rgba(183, 150, 157, 0.1);
    }
}
.side-card {
    border-radius: 20px 0 0 20px;
    background-color: white;
    box-shadow: 0 15px 35px 0 rgba(183, 150, 157, 0.1);
    .form-control {
        background-color: $lite-white;
        color: $gray;
        box-shadow: none;
        border: 1px solid $gray;
    }
}
.btn-close {
    &:focus {
        box-shadow: none;
    }
}
.close-btn {
    background-color: gray;
    color: white;
}
@media screen and (max-width: 992.5px) {
    .side-card {
        border-radius: 20px 20px 0 0 !important;
        background-color: white;
        box-shadow: 15px 0 35px 0 rgba(183, 150, 157, 0.1);
        .form-control {
            background-color: $lite-white;
            color: $gray;
            box-shadow: none;
            border: 1px solid $gray;
        }
    }
}

@media screen and (min-width: 992.5px) {
    .full-height {
        height: 100vh;
    }
    .row-height {
        height: 100%;
    }
}
@media screen and (max-width: 992.5px) {
    .car-image {
        width: 344.7px;
        height: 225px;
    }
}
@media screen and (max-width: 716.5px) {
    .car-image {
        width: 221.3px;
        height: 144.5px;
    }
}
@media screen and (max-width: 576px) {
    .submit-button {
        width: 100%;
    }
}
@media screen and (max-width: 767.5px) {
    .companylogo{
        height: 24px;
    }
    .details-section{
           .vehicle-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .properties{
            text-align: right;
        }
        }
}
.details-section.gray-bg{
    background-color: transparent !important;
}
.mobile-text{
    font-size: 14px;
}

.Feedback-emoji img{
    height: 34px;
    width: 34px;
}
}
.close-btn-cus {
    background-color: #f5f2fd;
    color: #797979;
    &:hover {
        color: #f5f2fd;
        background-color: #797979;
    }
}
.translate-mins {
    transform: translateY(49px);
}

.full-height-error-page{
    height: 100vh;
}



// d-flex justify-content-between align-items-center small
// d-flex justify-content-between align-items-center small
// d-flex justify-content-between align-items-center small
// d-flex justify-content-between align-items-center small
// d-flex justify-content-between align-items-center small