:root {
   
    --bs-gray: #bcbcbc;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: rgb(255, 41, 91);
    --bs-primary-hover:hwb(0 12% 0%);

    --bs-secondary: #f5f8fa;
    

    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f9f9f9;
    --bs-dark: #212529;
    --bs-primary-rgb:255, 41, 91;
    --bs-secondary-rgb:  rgba(255, 41, 91, 0.096);;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text: #ff295c;
    --bs-secondary-text: #6c757d;
    --bs-success-text: #146c43;
    --bs-info-text: #087990;
    --bs-warning-text: #997404;
    --bs-danger-text: #b02a37;
    --bs-light-text: #f9f9f9;
    --bs-dark-text: #495057;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
   
    --bs-body-color: #1e2f46;
    --bs-heading-color: #1e2f46;
   
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-link-color: #0d6efd;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: none;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202; 
   
}

$primery:#ff295c;
$primery-hover:#FF1E1E;
$heading:#1e2f46;
$gray:#bcbcbc;
$lite-white:#f9f9f9;

// btns

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-primary-hover);
    --bs-btn-hover-border-color: var(--bs-primary-hover);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-primary-hover);
    --bs-btn-active-border-color: var(--bs-primary-hover);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ff8da8;
    --bs-btn-disabled-border-color: #ff8da8;
}
.btn-close-modal {
    --bs-btn-color:#797979;
    --bs-btn-bg:var(--bs-secondary);
    --bs-btn-border-color: #f5f2fd;
    --bs-btn-hover-color:var(--bs-primary);
    --bs-btn-hover-bg: var(--bs-secondary-rgb);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color:var(--bs-primary);
    --bs-btn-active-bg:var(--bs-secondary-rgb);
    --bs-btn-active-border-color:transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}
.btn-light-primary{
    --bs-btn-color:var(--bs-body-color);
    --bs-btn-bg:var(--bs-light);
    --bs-btn-border-color:var(--bs-primary);
    --bs-btn-hover-color:var(--bs-primary);
    --bs-btn-hover-bg: var(--bs-light);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg:var(--bs-primary);
    --bs-btn-active-border-color:var(--bs-primary);
    --bs-btn-active-shadow: transparent;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d; 
}
.btn-light-custom{
    --bs-btn-color:var(--bs-primary);
    --bs-btn-bg:var(--bs-light);
    --bs-btn-border-color:var(--bs-primary);
    --bs-btn-hover-color:#fff;
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg:var(--bs-primary);
    --bs-btn-active-border-color:var(--bs-primary);
    --bs-btn-active-shadow: transparent;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d; 
}